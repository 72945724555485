import { Observable, map, pipe, take } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { PrimitiveJsonResponse } from "../../models/http/primitive-json-response.model";
import { AvailableStudentLicensesSummaries } from "../../models/student/licenses/available-student-licenses-summary.model";
import { ServiceBase } from "../http/service-base";
import { Apollo } from "apollo-angular";
import { StudentLicenseSummaryFilter } from "../../models/student/licenses/student-licenses-summary-filter.model";
import { StudentLicenseSummary } from "../../models/student/licenses/student-licenses-summary.model";
import { PagingParameters } from "../../models/http/paging-parameters.model";
import { Page } from "../../models/essential/page";
import { extractPaging } from "../../pagination-util";
import { StudentLicense } from "../../models/student/licenses/student-license.model";
import { StudentLicenseFilter } from "../../models/student/licenses/student-licenses-filter.model";
import { AvailableStudentLicenseFiltersResponse } from "../../models/student/licenses/available-licenses-filter-response.model";

@Injectable({
  providedIn: "root",
})
export class StudentService extends ServiceBase {
  getApiEndpoint(): string {
    return `${environment.baseUrl}/api/student`;
  }

  constructor(
    protected http: HttpClient,
    protected apollo: Apollo
  ) {
    super(http, apollo);
  }

  disableEntity(id: number): Observable<PrimitiveJsonResponse<number>> {
    return this.http
      .patch<PrimitiveJsonResponse<number>>(this.buildPath(`/${id}`), null)
      .pipe(take(1));
  }

  getAvailableLicenseSummaries(): Observable<
    AvailableStudentLicensesSummaries[]
  > {
    return this.http
      .get<
        AvailableStudentLicensesSummaries[]
      >(this.buildPath("/licenses/summaries/available"))
      .pipe(take(1));
  }

  listLicenseSummaries(
    filters: StudentLicenseSummaryFilter,
    paging: PagingParameters
  ): Observable<Page<StudentLicenseSummary>> {
    let queryParams = new HttpParams()
      .set("idNetwork", filters.idNetwork)
      .set("pageNumber", paging.pageNumber?.toString() ?? "1")
      .set("pageSize", paging.pageSize?.toString() ?? "20");

    if (filters.idSubNetwork) {
      queryParams = queryParams.append("idSubNetwork", filters.idSubNetwork);
    }

    if (filters.status) {
      queryParams = queryParams.append("status", filters.status);
    }

    if (filters.month && filters.year) {
      queryParams = queryParams.append("month", filters.month.toString());
      queryParams = queryParams.append("year", filters.year.toString());
    }

    return this.http
      .get<any>(this.buildPath("/licenses/summaries"), {
        params: queryParams,
        observe: "response" as const,
      })
      .pipe(
        take(1),
        map((response) => {
          const result = extractPaging<StudentLicenseSummary>(response);
          return result;
        })
      );
  }

  getLicenseSummary(id: string): Observable<StudentLicenseSummary> {
    return this.http
      .get<StudentLicenseSummary>(this.buildPath(`/licenses/summary/${id}`))
      .pipe(take(1));
  }

  listLicenses(
    summaryId: string,
    filters: StudentLicenseFilter,
    paging: PagingParameters
  ): Observable<Page<StudentLicense>> {
    const queryParams = this.filtersToParams(filters)
      .set("pageNumber", paging.pageNumber?.toString() ?? "1")
      .set("pageSize", paging.pageSize?.toString() ?? "20");

    return this.http
      .get<any>(this.buildPath(`/licenses/by-summary/${summaryId}`), {
        params: queryParams,
        observe: "response" as const,
      })
      .pipe(
        take(1),
        map((response) => {
          const result = extractPaging<StudentLicense>(response);
          return result;
        })
      );
  }

  setLicenseReportStatus(summaryId: string, status: string) {
    return this.http
      .patch(
        this.buildPath(`/licenses/summary/${summaryId}/set-status/${status}`),
        {}
      )
      .pipe(take(1));
  }

  getAvailableLicenseFilters(
    summaryId: string
  ): Observable<AvailableStudentLicenseFiltersResponse> {
    return this.http
      .get<AvailableStudentLicenseFiltersResponse>(
        this.buildPath(`/licenses/summary/${summaryId}/available`)
      )
      .pipe(take(1));
  }

  createLicenseReportNote(summaryId: string, notes: string) {
    const obj = {
      description: notes,
    };

    return this.http
      .post(this.buildPath(`/licenses/summary/${summaryId}/notes`), obj)
      .pipe(take(1));
  }

  deleteLicense(id: string, reason: string) {
    const obj = {
      reason: reason,
    };

    return this.http
      .delete(this.buildPath(`/licenses/${id}`), { body: obj })
      .pipe(take(1));
  }

  deleteLicenses(
    summaryId: string,
    reason: string,
    filters: StudentLicenseFilter
  ) {
    const obj = {
      reason: reason,
    };

    const queryParams = this.filtersToParams(filters);

    return this.http
      .delete(this.buildPath(`/licenses/summary/${summaryId}/batch`), {
        body: obj,
        params: queryParams,
      })
      .pipe(take(1));
  }

  deleteReportNote(idSummary: string, idNote: string) {
    return this.http
      .delete(this.buildPath(`/licenses/summary/${idSummary}/notes/${idNote}`))
      .pipe(take(1));
  }

  updateReportNote(idSummary: string, idNote: string, notes: string) {
    const obj = {
      description: notes,
    };

    return this.http
      .put(
        this.buildPath(`/licenses/summary/${idSummary}/notes/${idNote}`),
        obj
      )
      .pipe(take(1));
  }

  dowloadLicenseReportExcel(id: string) {
    return this.http
      .get<any>(this.buildPath(`/licenses/summary/${id}/export-sheet`), {
        responseType: "blob" as "json",
        observe: "response" as "body",
      })
      .pipe(
        take(1),
        map((response: HttpResponse<Blob>) => {
          const contentDisposition = response.headers.get(
            "Content-Disposition"
          );
          const filename = contentDisposition
            ?.split(";")[1]
            .split("filename")[1]
            .split("=")[1]
            .trim();
          return { content: response.body, filename: filename };
        })
      );
  }

  private filtersToParams(filters: StudentLicenseFilter): HttpParams {
    let queryParams = new HttpParams();

    if (filters.idSubNetwork) {
      queryParams = queryParams.append("idSubNetwork", filters.idSubNetwork);
    }

    if (filters.idSchool) {
      queryParams = queryParams.append("idSchool", filters.idSchool);
    }

    if (filters.coStage) {
      queryParams = queryParams.append("coStage", filters.coStage);
    }

    if (filters.coGrade) {
      queryParams = queryParams.append("coGrade", filters.coGrade);
    }

    if (filters.likeName) {
      queryParams = queryParams.append("likeName", filters.likeName);
    }

    if (filters.email) {
      queryParams = queryParams.append("email", filters.email);
    }

    return queryParams;
  }
}
